import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { listBlogs } from '../../../actions/blogActions';
import LoadingComponent from '../../loading/LoadingComponent';
import NavBar from '../../navbar/NavBar';
import './ComponentBlog.css'

const ComponentBlog = (props) => {
    const blogList = useSelector((state) => state.blogList);
    const { blogs } = blogList;   
    const [selectedBlog, setSelectedBlog] = useState({}) 
    const [visible, setVisible] = useState(true)
    const [recharge, setRecharge] = useState(false)

    const dispatch = useDispatch();
    useEffect(() => {        
         dispatch(listBlogs()); 
         searchBlog();
        //eslint-disable-next-line
      }, [recharge]);
      
      const searchBlog = async () => {
         setTimeout(() => {
            blogs !== undefined ?
            blogs.map(blog => {
                if(blog.idname === props.match.params.id){
                    setSelectedBlog(blog);
                }
                setVisible(false);
                return null;
            })
            : 
            setRecharge(true)
         }, 2000);

         
        
 }
    return (
        <>
        <NavBar />
        <div className="main al100">
        <div className='blog-container'>
            <div className="blog-content">
            {!visible? 
            <>
            <button className="button" onClick={()=>{window.history.back()}} style={{position:'fixed', right:0, marginRight:'20px', width:"100px", opacity:"50%", background:'--var(secondary-color)'}}>Atrás</button>
            <p dangerouslySetInnerHTML={{__html: selectedBlog.description}} />  
            </>
            :
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'75vh'}}>
            <LoadingComponent size={250}/>
            </div>
            }
        </div>
</div>
</div>
</>
    )
}


export default ComponentBlog;