import React from 'react'

export const NuestraTienda = () => {
    return (
        <div className='about-description'>
        <h1>Encontrá en</h1>
        <h2>AL NORTE GrowShop</h2>
        <p>Armarios de cultivo, iluminación, sustratos, fertilizantes, accesorios de iluminación y ventilación y cientos de productos más!</p>
        
        <p>Te ayudamos a construir un cultivo a la medida de tus necesidades.</p>
        <p>En AL NORTE GrowShop te acompañamos desde nuestro conocimiento y experiencia como cultivadores con asesorías personalizadas.</p>
        <div className="buttonContainer"><button className="button" onClick={()=> {window.open(process.env.REACT_APP_TIENDA_URL)}}><h2>Catalogo de productos</h2></button></div>
      </div>
    )
}
