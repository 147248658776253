import React, { useState } from 'react';

const FooterContact = () => {
  const [animate, setAnimate] = useState(false);

  return (
    <div className='footer-row'>
    <div className='footer-info' onMouseOver={() =>setAnimate(true)} onMouseLeave={()=>setAnimate(false)}>
        <h2 className={animate ? "animate__animated animate__bounce" : ""}>AL NORTE
        <label style={{color:'var(--secondary-color)', textShadow: '0 1px 0 black, 0 1px 0 black, 0 1px 0 black, 0 1px 0 black, 0 1px 0 black, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'}}> GrowShop</label></h2>
        <p className={animate ? "animate__animated animate__bounce" : ""}>📍 Eva Perón 23 (Salta Norte), San Fernando del Valle de Catamarca, Catamarca 🌵</p>
        <br/>
        <a href="https://www.google.com/maps/place/Al+Norte+Growshop/@-28.4602891,-65.7819411,17z/data=!3m1!4b1!4m6!3m5!1s0x942429a1ef35f4cd:0x6b226cbe12aedb45!8m2!3d-28.4602939!4d-65.7770702!16s%2Fg%2F11ts33tmph?hl=es-419&entry=ttu"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.6392550915!2d-65.78194111265444!3d-28.460289097120537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942429a1ef35f4cd%3A0x6b226cbe12aedb45!2sAl%20Norte%20Growshop!5e0!3m2!1ses-419!2sar!4v1692675935222!5m2!1ses-419!2sar" width="400" height="300" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="AlNorteGrow"></iframe></a>
      </div>
    </div>
  );
};

export default FooterContact;
