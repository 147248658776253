import React from 'react'
import { Link } from 'react-router-dom'

export const TuMedicina = () => {
    return (
        <div className='about-description'>
        <h1>Cultiva</h1>
        <h2>tu medicina</h2>
        <p>Otra calidad de vida es posible.</p>
        
        <p>Conoce las propiedades medicinales que nos ofrece el Cannabis y cómo usarlas o prepararlas, todo esto en nuestro blog y consejos.</p>
        <p>Porque para aprovechar al máximo sus beneficios, estar informados es una herramienta rápida y útil.</p>
        <div className="buttonContainer"><Link to="/cultivatumedicina" onClick={()=> {window.scrollTo(0, 0);}}><button className="button"><h2>Quiero saber más</h2></button></Link></div>
        <br/>  
      </div>
    )
}
