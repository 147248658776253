import React from 'react';
import SocialNetworkItem from '../contact/SocialNetworkItem';

  const socialNetworks = [
    // {
    //   network: 'facebook',
    //   url: 'https://www.facebook.com/alnortegrow/',
    // },
    {
      network: 'whatsapp',
      url: 'https://api.whatsapp.com/send/?phone=5493835117722',
    },
    { 
      network: 'instagram',
      url: 'https://instagram.com/alnortegrow',
    }
  ];

const FooterExperience = () => {

  return (
    <div className='footer-row'>
      <div className='footer-info'>
        
        <h2>Contacto</h2>
        <label style={{color:'var(--secondary-color)', textShadow: '0 1px 0 black, 0 1px 0 black, 0 1px 0 black, 0 1px 0 black, 0 1px 0 black, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'}}> alnortegrow@gmail.com</label>
        
      <div className='footer-contact'>
        <h3>Nuestras redes</h3>
        <div className='sns-links'>
          {socialNetworks.length !== 0 ? (
            socialNetworks.map((socialNetwork, index) => (
              <SocialNetworkItem
                key={index}
                network={socialNetwork.network}
                url={socialNetwork.url}
                color={socialNetwork.color}
              />
            ))
          ) : (
            <></>
          )}
          </div>
      </div>
      </div>
    </div>
  );
};

export default FooterExperience;
