import { BLOG_GET_REQUEST, BLOG_GET_SUCCESS, BLOG_GET_FAIL, BLOG_LIST_FAIL, BLOG_LIST_REQUEST, BLOG_LIST_SUCCESS, BLOG_SAVE_FAIL, BLOG_SAVE_REQUEST, BLOG_SAVE_SUCCESS } from '../constants/blogConstants';
  
  function blogListReducer(state = { categorys: [] }, action) {
    switch (action.type) {
      case BLOG_LIST_REQUEST:
        return { loading: true, blogs: [] };
      case BLOG_LIST_SUCCESS:
        return { loading: false, blogs: action.payload };
      case BLOG_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  function blogGetReducer(state = { blog: {} }, action) {
    switch (action.type) {
      case BLOG_GET_REQUEST:
        return { loading: true, blog: {} };
      case BLOG_GET_SUCCESS:
        return { loading: false, blog: action.payload };
      case BLOG_GET_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }
  
  function blogSaveReducer (state = { blog: {} }, action) {
    switch (action.type) {
      case BLOG_SAVE_REQUEST:
        return { loading: true };
      case BLOG_SAVE_SUCCESS:
        return { loading: false, success: true, blog: action.payload };
      case BLOG_SAVE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }
  
  export {
    blogListReducer,
    blogSaveReducer,
    blogGetReducer
  };
  