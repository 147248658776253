import React from 'react'

export const Reprocann = () => {
    return (
        <div className='about-description'>
<h3>Profesionales especialistas</h3>
<h4>en Endocannabinología</h4> 
<p>Descargate ahora la cartilla de Profesionales Especialistas  en Edocannabinología y enterate del  lugar más cercano a tu domicilio.</p>
<div className="buttonContainer">
    <a
        href={`https://drive.google.com/file/d/1mQX9rLjwuxMagp1xj0zl2olAGlEC-SSR/view?usp=sharing`}        
        target='_blank'
        rel='noreferrer'
      ><button className="button"><h2>Descargar</h2></button></a></div>
<br/>  
</div>
    )
}
