import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Cookie from 'js-cookie';

import {
  userSigninReducer,
  userRegisterReducer,
  updateVariablesReducer,
  getVariablesReducer,
  userUpdateReducer,
  userRecoveryReducer
} from './reducers/userReducers';
import {
  blogGetReducer,
  blogListReducer,
  blogSaveReducer
} from './reducers/blogReducers';

const userInfo = Cookie.getJSON('userInfo') || undefined;

const initialState = {
  userSignin: { userInfo }
};

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  updateVariables: updateVariablesReducer,
  getVariables: getVariablesReducer,
  userUpdate: userUpdateReducer,
  userRecovery: userRecoveryReducer,
  blogList: blogListReducer,
  blogSave: blogSaveReducer,
  blogGet: blogGetReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const store  = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);



 


