const categorias = [
    {
      id: 1,
      name: 'Carpas',
      img: require(`../../media/categorias/carpas/carpas1.png`).default,
      img2: require(`../../media/categorias/carpas/carpas2.png`).default,
      info: 'Carpas',
      description:'Elige el tamaño ideal para tu cultivo.',
      url: process.env.REACT_APP_TIENDA_URL
    },
    {
      id: 2,
      name: 'Iluminación',
      img: require(`../../media/categorias/iluminacion/iluminacion1.png`).default,
      img2: require(`../../media/categorias/iluminacion/iluminacion2.png`).default,
      info: 'Iluminación',
      description:'Dale a tus plantas la luz que merecen.',
      url: process.env.REACT_APP_TIENDA_URL
    },   
    {
      id: 3,
      name: 'Fertilizantes',
      img: require(`../../media/categorias/fertilizantes/fertilizantes1.png`).default,
      img2: require(`../../media/categorias/fertilizantes/fertilizantes2.png`).default,
      info: 'Fertilizantes',
      description:'Orgánico o Mineral, los tenemos todos!',
      url: process.env.REACT_APP_TIENDA_URL
    },      
    {
      id: 4,
      name: 'Accesorios',
      img: require(`../../media/categorias/accesorios/accesorios1.png`).default,
      img2: require(`../../media/categorias/accesorios/accesorios2.png`).default,
      info: 'Accesorios',
      description:'Para cada cultivo, lo que necesitas.',
      url: process.env.REACT_APP_TIENDA_URL
    },      
    {
      id: 5,
      name: 'Parafernalia',
      img: require(`../../media/categorias/parafernalia/parafernalia1.png`).default,
      img2: require(`../../media/categorias/parafernalia/parafernalia2.png`).default,
      info: 'Parafernalia',
      description:'Todos tus chiches.',
      url: process.env.REACT_APP_TIENDA_URL
    },
    {
      id: 6,
      name: 'Sustratos',
      img: require(`../../media/categorias/sustratos/sustratos1.png`).default,
      img2: require(`../../media/categorias/sustratos/sustratos2.png`).default,
      info: 'Sustratos',
      description:'Tierra fertil, variedad y calidad.',
      url: process.env.REACT_APP_TIENDA_URL
    },    
    {
      id: 7,
      name: 'Macetas',
      img: require(`../../media/categorias/macetas/macetas1.png`).default,
      img2: require(`../../media/categorias/macetas/macetas2.png`).default,
      info: 'Macetas',
      description:'Macetas de diferentes tamaños y tipos.',
      url: process.env.REACT_APP_TIENDA_URL
    },  
    {
      id: 8,
      name: 'Medidores',
      img: require(`../../media/categorias/medidores/medidores1.png`).default,
      img2: require(`../../media/categorias/medidores/medidores2.png`).default,
      info: 'Medidores',
      description:'Medi tu cultivo.',
      url: process.env.REACT_APP_TIENDA_URL
    },
    {
      id: 9,
      name: 'Ventilación',
      img: require(`../../media/categorias/filtros/filtros1.png`).default,
      img2: require(`../../media/categorias/filtros/filtros2.png`).default,
      info: 'Filtros',
      description:'Mas barato que un abogado.',
      url: process.env.REACT_APP_TIENDA_URL
    },
    {
      id: 10,
      name: 'Packs',
      img: require(`../../media/categorias/packs/packs1.png`).default,
      img2: require(`../../media/categorias/packs/packs2.png`).default,
      info: 'Packs',
      description:'Packs de variedades de cosas.',
      url: process.env.REACT_APP_TIENDA_URL
    },
    {
      id: 11,
      name: 'Prevencion',
      img: require(`../../media/categorias/prevencion/prevencion1.png`).default,
      img2: require(`../../media/categorias/prevencion/prevencion2.png`).default,
      info: 'Prevención',
      description:'Protegé tu cultivo desde el principio.',
      url: process.env.REACT_APP_TIENDA_URL
    },
    {
      id: 12,
      name: 'Promociones',
      img: require(`../../media/categorias/promociones/promociones1.png`).default,
      img2: require(`../../media/categorias/promociones/promociones2.png`).default,
      info: 'Promociones',
      description:'Todos las promociones de la tienda.',
      url: process.env.REACT_APP_TIENDA_URL
    },  
  ];

export default categorias;
