import React, { useState } from 'react';

const CategoriaItem = ({ categoria }) => {
  
  const [overskill, setOverskill] = useState(false);
  const [image, setImage] = useState(categoria.img)

  return (
    <div className={overskill ? 'technologies-item over' : 'technologies-item'} 
    onMouseOver={() => {setOverskill(true); setImage(categoria.img2)}}
    onMouseLeave={() => {setOverskill(false); setImage(categoria.img)}}>
      <img
        className='img-technologies'
        src={image}
        alt={categoria.info}
      />
      <label className='skillnametitle'>{categoria.name}</label>
      <label className='skillname'>{categoria.description}</label>
    </div>
  );
};

export default CategoriaItem;
