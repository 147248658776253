import React, { useState } from 'react';
import { Link } from 'react-router-dom';  
import { Link as LinkScroll } from 'react-scroll';
import './Navbar.css';
import imgLogo from '../../media/logo.png'
const NavBar = ({ isScrolling, staticnav}) => {
  const toTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const [active, setActive] = useState(false);
  const width = window.innerWidth;

  const closeMenu = () => {
    setActive(!active);
  };


  return (
    <div className="wrapper">
    <div className="flash-component" >
      Sitio Web para mayores de 18 años
  </div>
    <nav className={`navbar ${staticnav ? 'static' : null} ${isScrolling > 20 ? 'scrolling' : null}`} >
      <div className='navbar-left'>
      <Link
      to={"/"}
      onClick={toTop}
      ><div className='nav-logo'>
        
      <img src={imgLogo} className="img-logo" alt="AlNorteGrow"/>
      
      </div></Link>
      <Link
      to={"/"}
      onClick={toTop}
      ><div className='nav-logo-text'>
      <h3>AL NORTE</h3>
      </div>
      <div className='nav-logo-mini-text'>
      <h4>AL NORTE</h4>
      </div>
      </Link>
      </div>
      
<div className='right-nav'>
      {!staticnav ? 
      
        <ul className='nav-menu' style={active ? { display: 'flex' } : {}} >
              <LinkScroll
              to='tienda'
              smooth="true"
              offset={-70}
              // onClick={!secondClick ? closeMenu : () => window.open(process.env.REACT_APP_TIENDA_URL) }
              // onDoubleClick={() => window.open(process.env.REACT_APP_TIENDA_URL)}
              onClick={() => window.open(process.env.REACT_APP_TIENDA_URL)}
            >
              <li className='nav-item'>
              🌱 Tienda Virtual
          </li>
            </LinkScroll>
            <LinkScroll
              to='medicina'
              smooth="true"
              offset={-70}
              onClick={() => window.location.href = '/cultivatumedicina'}
            >
            <li className='nav-item' style={width > 960 ? {width:300} : {}}>
           💚 Cultivo Medicinal
          </li>
            </LinkScroll>
            </ul>
      :
        <ul className='nav-menu' style={active ? { display: 'flex' } : {}}>
       
            <Link
              to='/tienda'
              offset={-70}
              onClick={closeMenu}
            >
            <li className='nav-item'>
            Tienda
          </li>
            </Link>
            <Link to="/categoria/promociones" >
              <li className='nav-item'>Promociones
          </li>
          </Link>
            <Link
              to='/carrito'
              offset={-70}
              onClick={closeMenu}
            >
            </Link>          
            
        </ul>
  }
      <div
        className={active ? `hamburger active` : `hamburger`}
        onClick={closeMenu}
      >
        <span className='bar'></span>
        <span className='bar'></span>
        <span className='bar'></span>
      </div>
      
      </div>
      <a
        href={`https://wa.me/5493835117722`}
        className="whatsapp_float"
        target='_blank'
        rel='noreferrer'
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
  
    </nav>

    </div>
  );
};

export default NavBar;
