import React from 'react';
const ErrorComponent = ({size}) => {
    return (
        <div className="loading-container">
            {size < 100 ?
            <></>
            :
            <h3>Error al cargar los recursos</h3>
            }
            
        </div>
    )
}

export default ErrorComponent
