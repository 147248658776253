import React from 'react';
import './App.css';
import imgEncontra1 from '../src/media/tienda/encontra1.png';
import imgEncontra2 from '../src/media/tienda/encontra2.png';
import imgTuMedicina1 from '../src/media/tienda/tumedicina1.png';
import imgTuMedicina2 from '../src/media/tienda/tumedicina2.png';
import Cover from'./components/cover/Cover';
import Navbar from './components/navbar/NavBar';
import Footer from './components/footer/Footer';
import CategoriasSection from './components/categorias/CategoriasSection';
import ComponentInfo from './components/about/ComponentInfo';
import { NuestraTienda } from './components/about/NuestraTienda';
import { TuMedicina } from './components/about/TuMedicina';

function App() {

  return (
    <div className='App'>
      <Navbar staticnav={false}/>
      <Cover />      
      <CategoriasSection />
      <ComponentInfo id="tienda" orientation="left" description={<NuestraTienda/> } img={[imgEncontra1, imgEncontra2]}/>
      <ComponentInfo id="medicina" orientation="right" description={<TuMedicina/> } img={[imgTuMedicina1, imgTuMedicina2]}/>
      <Footer />
    </div>
  );
}

export default App;
