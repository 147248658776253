import React from 'react';
import './Cover.css';
import CoverVideo from '../../media/cover.mp4';

const Cover = () => {
    return(
        <div className='cover-container'>
            <video className='video' src={CoverVideo} autoPlay loop muted />
            <h1 className='cover-name'>AL NORTE</h1>
            <p>Te asesoramos construyendo TÚ cultivo a la medida de TUS necesidades</p>
            <p>Encontrá lo que buscas en nuestra tienda</p>            
            <div className="buttonContainer"><button className="button" onClick={()=> {window.open(process.env.REACT_APP_TIENDA_URL)}}><h2>Tienda virtual</h2></button></div>
        </div>
    )
}

export default Cover;