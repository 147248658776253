import React from 'react'
import { Link } from 'react-router-dom'

export const QueEsElTHC = () => {
    return (
        <div className='about-description'>
<h3>¿Que es</h3>
<h4>el THC?</h4> 
<p>El THC, o tetrahidrocannabinol, es el cannabinoide más conocido de la marihuana. Se encuentra en mayor proporción en la planta, y es responsable de los efectos psicoactivos (alteración de la percepción y modificación del estado de ánimo).</p>
<p>También tiene beneficios medicinales, aplicaciones terapéuticas.</p>
<div className="buttonContainer">
    <Link to="/blog/thc"><button className="button"><h2>Leer más</h2></button></Link></div>
<br/>  
</div>
    )
}
