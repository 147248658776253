import {
    BLOG_LIST_REQUEST,
    BLOG_LIST_SUCCESS,
    BLOG_LIST_FAIL,
    BLOG_SAVE_REQUEST,
    BLOG_SAVE_SUCCESS,
    BLOG_SAVE_FAIL,
    BLOG_GET_SUCCESS,
    BLOG_GET_FAIL,
    BLOG_GET_REQUEST
  } from '../constants/blogConstants';
  import clienteAxios from "../config/axios";
  

  const listBlogs = () => {
    return async (dispatch) => { 
      dispatch({ type: BLOG_LIST_REQUEST });
  
      try {
        const { data } = await clienteAxios.get(
          '/api/blogs'
        );
        dispatch({ type: BLOG_LIST_SUCCESS, payload: data.blogs });
      } catch (error) {
        dispatch({ type: BLOG_LIST_FAIL, payload: error.message });
      }
    }
  }
  
  const getBlog = (idname) =>  async (dispatch) => { 
      dispatch({ type: BLOG_GET_REQUEST });  
      try {
        const { data } = await clienteAxios.get(
          `/api/blogs/${idname}`
        );
        dispatch({ type: BLOG_GET_SUCCESS, payload: data[0] });
      } catch (error) {
        dispatch({ type: BLOG_GET_FAIL, payload: error.message });
      }
    
  
};

  const saveBlog = (blog) => async (dispatch, getState) => {
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      
      
      dispatch({ type: BLOG_SAVE_REQUEST, payload: blog });
      if (!blog._id) {
        
        const { data } = await clienteAxios.post('/api/blogs', blog, {
          headers: {
            Authorization: 'Bearer ' + userInfo.token,
          },
        });
        dispatch({ type: BLOG_SAVE_SUCCESS, payload: data });
        
      } else {
        const { data } = await clienteAxios.put(
          '/api/blogs/' + blog._id,
          blog,
          {
            headers: {
              Authorization: 'Bearer ' + userInfo.token,
            },
          }
        );
        
        
        dispatch({ type: BLOG_SAVE_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({ type: BLOG_SAVE_FAIL, payload: error.message });
    }
  };
   
  
  
  
  export {
    listBlogs,
    saveBlog,
    getBlog
  };
  