import clienteAxios from "../config/axios";

import Cookie from 'js-cookie';
import {
  USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL, USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS, USER_REGISTER_FAIL, USER_LOGOUT, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL,
  USER_VARIABLES_REQUEST, USER_VARIABLES_SUCCESS, USER_VARIABLES_FAIL, USER_RECOVERY_REQUEST, USER_RECOVERY_SUCCESS, USER_RECOVERY_FAIL
} from "../constants/userConstants";

const update = ({ userId, name, email, password, newpassword}) => async (dispatch, getState) => {
  const { userSignin: { userInfo } } = getState();

  dispatch({ type: USER_UPDATE_REQUEST, payload: { userId, name, email, password, newpassword} });
  try {
    const { data } = await clienteAxios.put("/api/users/" + userId,
      { name, email, password, newpassword }, {
      headers: {
        Authorization: 'Bearer ' + userInfo.token
      }
    });
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    Cookie.set('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_UPDATE_FAIL, payload: error.response.data.message });
  }
}


const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  
  try {
    const { data } = await clienteAxios.post("/api/users/signin", { email, password });
    
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    Cookie.set('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_SIGNIN_FAIL, payload: error.response.data.message });
  }
}

const register = (name, email, password) => async (dispatch) => {
  
  dispatch({ type: USER_REGISTER_REQUEST, payload: { name, email, password } });
  try {
    const { data } = await clienteAxios.post("/api/users/register", { name, email, password });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    Cookie.set('userInfo', JSON.stringify(data));

  } catch (errorRecovery) {
    dispatch({ type: USER_REGISTER_FAIL, payload: errorRecovery.response.data.message });
    
  }
}

const logout = () => (dispatch) => {
  Cookie.remove("userInfo");
  dispatch({ type: USER_LOGOUT })

}



const recoveryPass = (email) => async (dispatch)  => {
  try {
      dispatch({ type: USER_RECOVERY_REQUEST, payload: { email } });
      const { data } = await clienteAxios.post("/api/users/recovery", { email });
      
      // eslint-disable-next-line 
      const mail = {
        asunto: 'Recuperación de password - '+process.env.REACT_APP_NAMEWEB,
        titulo: 'Recibimos una solicitud de recuperación de password en Tienda Online.',
        mensaje: 'Tu nueva password es: '+data.newpass+' - Si no realizaste ninguna solicitud de recuperación password, ignora este mensaje.',
        nombre: process.env.REACT_APP_NAMEWEB,
        url: process.env.REACT_APP_URL,
        telefono: process.env.REACT_APP_TELEFONO}
      
      // emailjs.send(process.env.REACT_APP_MAILSERVICE,process.env.REACT_APP_MAILTEMPLATE_RECOVERPASS, mail , process.env.REACT_APP_MAILUSER)
      .then(function(response) {
      }, function(error) {
      console.log('Falló el envío...', error);
    });

      dispatch({ type: USER_RECOVERY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_RECOVERY_FAIL, payload: error.response.data.message });

}
}

//Variables 

const updateVar = ({ userInfo, pageName, titleHeader, descriptionHeader, backgroundHeader, logoHeader, carouselImg1, carouselImg2, carouselImg3, ubicacion, telefono, emailweb, slogan, descuento}) => async (dispatch, getState) => {
  dispatch({ type: USER_VARIABLES_REQUEST, payload: { userInfo, pageName, titleHeader, descriptionHeader, backgroundHeader, logoHeader, carouselImg1, carouselImg2, carouselImg3, ubicacion, telefono, emailweb, slogan, descuento} });
  try {
   
    const { data } = await clienteAxios.put("/api/users/variables", { userInfo, pageName, titleHeader, descriptionHeader, backgroundHeader, logoHeader, carouselImg1, carouselImg2, carouselImg3, ubicacion, telefono, emailweb, slogan, descuento});
    
    dispatch({ type: USER_VARIABLES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_VARIABLES_FAIL, payload: error.response.data.message });
  }
}


const getVariables = () => async (dispatch) => {
  try {
    dispatch({ type: USER_VARIABLES_REQUEST });
    const { data } = await clienteAxios.get("/api/users/variables");
    
    dispatch({ type: USER_VARIABLES_SUCCESS, payload: data.variables });
  } catch (error) {
    dispatch({ type: USER_VARIABLES_FAIL, payload: error.message });
  }
};

export { signin, register, logout, update, updateVar, getVariables, recoveryPass };