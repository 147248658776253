import React from 'react';
import ComponentMedicinaInfo from './ComponentMedicinaInfo';
import './CultivaMedicinaScreen.css';
import { Reprocann } from './Reprocann';
import imgReprocann from '../../media/blog/reprocann.png'
import imgMarihuanaMedicinal from '../../media/blog/marihuanamedicinal.png'
import imgQueEsElTHC from '../../media/blog/thc.png' 
import { MarihuanaMedicinal } from './MarihuanaMedicinal';
import { QueEsElTHC } from './QueEsElTHC';
import NavBar from '../navbar/NavBar';
function CultivaMedicinaScreen() {

 
  return (
    <>
      <NavBar  />
      <div className="main al100">
        <div className="tumedicina-container">
      <div className="text-center" >    
    <ComponentMedicinaInfo orientation="left" description={<Reprocann/> } imagen={imgReprocann}  />
    <ComponentMedicinaInfo orientation="right" description={<MarihuanaMedicinal/> } imagen={imgMarihuanaMedicinal}  />
    <ComponentMedicinaInfo orientation="left" description={<QueEsElTHC/> } imagen={imgQueEsElTHC}  />
              </div>
                </div>
                </div>
    </>
    )}

export default CultivaMedicinaScreen;