import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { logout } from '../../../actions/userActions';
import './AdminNavbar.css';

const AdminNavbar = () => {
  const toTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  //eslint-disable-next-line
  const [active, setActive] = useState(false);

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    <Redirect to="/"/>
  };

  return (    
    <nav className={'navbar'}  onClick={ active ? toTop : ()=>{}} >
      <Link
      to='/adminpanel'
      onClick={toTop}
      className='nav-logo'
      >   
      AL NORTE
      </Link>      
<div className='right-nav'>
        <ul className='nav-menu' style={active ? { display: 'flex' } : {}}>
            <Link to="/logout" onClick={handleLogout}><li className='nav-item'>Cerrar Sesión
          </li></Link>
        </ul>
      </div>
    </nav>
  );
};

export default AdminNavbar;
