import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { uploadImg } from '../../actions/productActions'
import { Redirect } from 'react-router-dom';
import { Alert, Button, Form, Input, InputGroup, Label, Modal, Spinner, Table } from 'reactstrap';
import AdminNavbar from '../nav/AdminNavbar';
import './SectionBlogsAdmin.css';
import editImg from '../../../media/elements/editar.png';
import addImg from '../../../media/elements/agregar.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Editor } from '@tinymce/tinymce-react';
import { listBlogs, saveBlog } from '../../../actions/blogActions';

function SectionBlogsAdmin() {

    const [detailsVisible, setDetailsVisible] = useState(false);
    const [selectedCategorie, setSelectedCategorie] = useState('Seleccione un blog');
    //Selector Usuario
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;

    //UseState Categoria
    const [modalVisible, setModalVisible] = useState(false);
    const [id, setId] = useState('');
    const [error, 
      // eslint-disable-next-line
      setError
    ] = useState(false);
    const [idname, setIdName] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    //Selector Listar Categorias
    const blogList = useSelector((state) => state.blogList);
    const { blogs } = blogList;
    
    //Selector Guardar Categorias
    const blogSave = useSelector((state) => state.blogSave);
    const {
      loading: loadingSave,
      success: successSave,
      error: errorSave,
    } = blogSave;

    const dispatch = useDispatch();
  
    useEffect(() => {
      if (successSave) {
        setModalVisible(false);
      }
      dispatch(listBlogs());
      return () => {
        //
      };
      
    }, [successSave, dispatch]);


    const openModal = (blog) => {
      setSelectedCategorie(blog.name)
      setDetailsVisible(true);
      setId(blog._id);
      setIdName(blog.idname);
      setName(blog.name);
      setDescription(blog.description);
    };
    
    const submitHandler = (e) => {
      e.preventDefault();
      dispatch(
        saveBlog({
          _id: id,
          idname,
          name,
          description,
          userInfo
        })
      );
      setDetailsVisible(false);
      setSelectedCategorie('Seleccione un blog');
    };

  
    const cancelAction = () => {
      setDetailsVisible(false);
      setSelectedCategorie('Seleccione un blog');
    }

    const resetForm = () => {
      setId('');
      setName('');
      setDescription('');  
    }
    
  const handleChange = (content) => {
    setDescription(content)
  }

    return (<>
    <AdminNavbar/>
<div className="main al100">
{userInfo.isAdmin ? '' : <Redirect to="/"/>}
        <br/>
        <div className="admin-products-container">     
        {modalVisible && (
            
            <div className="form">
                <Modal isOpen={modalVisible} toggle={openModal}>
                  <div className="modal-header main">
                    <button
                      aria-label="Close"
                      className="close"
                      type="button"
                      onClick={() => setModalVisible(false)}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                     {id ? 'Editar Producto' : 'Crear Producto'}
                    </h5>
                  </div>
                  <br/>
                  {loadingSave && <Spinner style={{ left: 0, bottom: 0, right: 0, top: 0, margin: "auto", width: '2rem', height: '2rem', position:"relative"}} />}
                    {errorSave && <div>  <Alert color="danger" className="text-center" style={{borderRadius:10}}>
          {errorSave}
        </Alert></div>}
                    {error ? (
          <div>  <Alert color="danger" className="text-center" style={{borderRadius:10}}>
          {error}
        </Alert></div>
        ) : null}
                  
                  
                </Modal>
              </div>)}
            <div className="products-container">
<div className="product-list">
  <div className="product-list-title">
            <img className="align-middle" src={addImg} width="30" height="30" alt={"AL NORTE"} onClick={() => {setDetailsVisible(true);resetForm()}}/><h2 className="align-middle header2">Blogs</h2>
  </div>
        <Table className="subtotal-table table-admin">
              <thead className="hide-element">
                <tr>
                <th className="subtotal-table-th text-center">BlogID</th>
                <th className="subtotal-table-th text-right">Nombre</th>
                {/* <th className="subtotal-table-th text-right">Descripcion</th> */}
                <th className="subtotal-table-th text-right">Acción</th>
                </tr>
              </thead>
              </Table>
          <div className="admin-items scrollable">
        <Table className="subtotal-table table-admin">
              <tbody>
              {blogs !== undefined ? blogs.map((blog) => (

                <tr key={blog._id} >
                 
                  <td className="card-table-td text-center">{blog.idname}</td>
                  <td className="card-table-td text-center">{blog.name}</td>
                  {/* <td className="card-table-td text-center">{blog.description}</td> */}
                  <td className="card-table-td center-img">
                  <img 
                      src={editImg}
                      width="30"
                      height="30"
                      alt={"AL NORTE"}
                      onClick={() =>openModal(blog)}
                      />         
                  </td>
                </tr>
               ))
               : 
               <tr >
                 <td>Cargando blogs...</td>
                </tr>
                }
                
              </tbody>
               </Table>
               </div>
               </div>
               <div className="product-edit-details">
               <div className="product-list-title">
  <h2>{selectedCategorie}</h2>
  </div>
  { detailsVisible ? 
               <Form onSubmit={submitHandler} autoComplete="none" >
               <div className="category-form">
               <InputGroup>
             <Label htmlFor="idname">Nombre ID</Label>
             <Input type="text" name="idname" value={idname? idname : ''} id="idname" onChange={(e) => setIdName(e.target.value)} required />
             </InputGroup>
              <InputGroup>
             <Label htmlFor="name">Titulo</Label>
             <Input type="text" name="name" value={name? name : ''} id="name" onChange={(e) => setName(e.target.value)} required />
             </InputGroup>
             <div className="description-textarea">
          <Label htmlFor="name">Contenido del blog</Label>
          <Editor
    value={description? description : ''}
    
 apiKey="7ujv8lmrkl017n4d0y3tzhxzlotpsr0osey1zesyc2i8wdrb"
    init={{
      height: 500,
      menubar: true
    }}
    onEditorChange={handleChange}
  />
  
                </div>
             <div className="buttons-content">
             <Button type="submit" className="button">
                   {id ? 'Actualizar' : 'Crear'}
                 </Button>
                 <Button
                   type="button"
                   onClick={cancelAction}
                   className="button"
                 >
                   Atrás
                 </Button>
                 </div>
               </div>
             </Form>
                : 
                <></>
}
               </div>
          </div>
               
          
          </div>
      </div>
      </>
    );
    
}

export default SectionBlogsAdmin;