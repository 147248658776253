import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect} from 'react-router-dom'

const RutaPrivadaAdmin = ({component: Component,...props}) => {
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    
    return (
       <> 
        {userInfo === undefined ? <Redirect to="/" /> : <Route {...props} render={props => userInfo.isAdmin !== true ? (
            <Redirect to="/" />
        ) : (
            <Component {...props} />
        ) }

        />}
        
        </>
    );
    
}
export default RutaPrivadaAdmin