import React from 'react';
import CategoriaItem from './CategoriaItem';
import categorias from './Categorias';
import '../about/ComponentInfo.css';

const CategoriasSection = () => {
  return (
    <div className='about-technologies' id='categorias'>
      <div className='about-technologies-title'>
        <h1>Categorias</h1>
      </div>
      <div className='about-technologies-items'>
        {categorias.length !== 0 ? (
          categorias.map((categoria) => <div key={categoria.id} onClick={()=>{window.open(categoria.url)}}><CategoriaItem categoria={categoria} /> </div>)
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CategoriasSection;
