import React from 'react';
import './ComponentMedicinaInfo.css';

const ComponentMedicinaInfo = ({orientation, description, imagen, size}) => {  
  

  return (
    <div className={`about-container`}>
      {orientation === "right" ?      
    <div className='about-info'>
      <div className='about-img'>     
        <img src={imagen} alt='AL NORTE' width={size} height={size} style={{borderRadius:15}}/>
      </div>     
      {description} 
    </div>
    :
    <div className='about-info'>   
      <div className='about-img'>      
      <img src={imagen} alt='AL NORTE' width={size} height={size} style={{borderRadius:15}}/>
      </div>    
    {description}     
    </div>
    }

    </div>
  );
};

export default ComponentMedicinaInfo;
