import React from 'react'
import { Link } from 'react-router-dom'

export const MarihuanaMedicinal = () => {
    return (
        <div className='about-description'>
<h3>Marihuana</h3>
<h4>Medicinal</h4> 
<p>Hace miles de años que se empezó a usar la marihuana con fines medicinales. Ya son muchos los países que incluyen esta sustancia en tratamientos médicos para tratar de aliviar dolores agudos.</p>
<p>El proceso consiste en utilizar las preparaciones o principios activos de la planta Cannabis. Se usa mucho para aliviar algunos síntomas de enfermedades o trastornos.</p>
<div className="buttonContainer">
    <Link to="/blog/marihuanamedicinal"><button className="button"><h2>Leer más</h2></button></Link></div>
<br/>  
</div>
    )
}
