import React from 'react';
import './Footer.css';
import FooterContact from './contact/FooterContact';
import FooterExperience from './experience/FooterExperience';
import imgLogo from '../../media/logo.png'

const Footer = () => {

  return (
    <footer>
      <div className='footer-box' >
      <div className='footer-container' id="contacto">
        
      <FooterContact />
        <div style={{display:'flex', justifyContent:'center',}}>
      <img src={imgLogo} className="img-logo-xl rotate" alt="AlNorteGrow"/>
      </div>
        <FooterExperience />
      </div>
      </div>
    </footer>
  );
};

export default Footer;
