export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST';
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS';
export const BLOG_LIST_FAIL = 'BLOG_LIST_FAIL';

export const BLOG_SAVE_REQUEST = 'BLOG_SAVE_REQUEST';
export const BLOG_SAVE_SUCCESS = 'BLOG_SAVE_SUCCESS';
export const BLOG_SAVE_FAIL = 'BLOG_SAVE_FAIL';

export const BLOG_GET_FAIL = 'BLOG_GET_FAIL';
export const BLOG_GET_REQUEST = 'BLOG_GET_REQUEST';
export const BLOG_GET_SUCCESS = 'BLOG_GET_SUCCESS';
