import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { Provider } from 'react-redux'; 
import {store} from './store'
import RutaPublica from './components/rutas/RutaPublica';
import LoginScreen from './components/login/LoginScreen';
import RutaPrivadaAdmin from './components/rutas/RutaPrivadaAdmin';
import ErrorComponent from './components/error/ErrorComponent';
import CultivaMedicinaScreen from './components/cultivamedicina/CultivaMedicinaScreen';
import ComponentBlog from './components/cultivamedicina/blog/ComponentBlog';
import SectionBlogsAdmin from './components/admin/blogs/SectionBlogsAdmin';



ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter >
    <Switch>
    <RutaPublica exact path="/" component={App} />
    <RutaPublica path="/administrargrow" component={LoginScreen} />
    <RutaPrivadaAdmin path="/adminblogs" component={SectionBlogsAdmin} />
    <RutaPublica path="/error" component={ErrorComponent} />
    <RutaPublica path="/cultivatumedicina" component={CultivaMedicinaScreen} />
    <RutaPublica path="/blog/:id" component={ComponentBlog} />
    
    
    <Redirect to="/" />
    </Switch>
  </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
