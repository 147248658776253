import { USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNIN_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_REGISTER_FAIL, USER_LOGOUT, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL, USER_VARIABLES_REQUEST, USER_VARIABLES_SUCCESS, USER_VARIABLES_FAIL, USER_RECOVERY_REQUEST, USER_RECOVERY_SUCCESS, USER_RECOVERY_FAIL } from "../constants/userConstants";

function userSigninReducer(state = {}, action) {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default: return state;
  }
}

function userUpdateReducer(state = {}, action) {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, userInfo: action.payload, error: false, success: true};
    case USER_UPDATE_FAIL:
      return { loading: false , error: action.payload };
    default: return state;
  }
}

function userRegisterReducer(state = {}, action) {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function updateVariablesReducer(state = {}, action) {
  switch (action.type) {
    case USER_VARIABLES_REQUEST:
      return { loading: true };
    case USER_VARIABLES_SUCCESS:
      return { loading: false, userInfo: action.payload, error: false, success: true};
    case USER_VARIABLES_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function getVariablesReducer(state = { variables: [] }, action) {
  switch (action.type) {
    case USER_VARIABLES_REQUEST:
      return { loading: true, variables: [] };
    case USER_VARIABLES_SUCCESS:
      return { loading: false, variables: action.payload };
    case USER_VARIABLES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userRecoveryReducer(state = {}, action) {
  switch (action.type) {
    case USER_RECOVERY_REQUEST:
      return { loading: true };
    case USER_RECOVERY_SUCCESS:
      return { loading: false, email: action.payload , success: true};
    case USER_RECOVERY_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  } 
}

export {
  userSigninReducer, userRegisterReducer, userUpdateReducer, updateVariablesReducer, getVariablesReducer, userRecoveryReducer
}