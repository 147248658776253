import React, { useState } from 'react';
// reactstrap components
import { Button, Card, Form, Input, Label, Alert, InputGroup } from "reactstrap";
import imgGrow from '../../media/tienda/nuestratienda.png'
import './LoginScreen.css';
import { useDispatch, useSelector } from 'react-redux';
import { signin } from '../../actions/userActions';
import { Redirect } from 'react-router-dom';

const LoginScreen = () => {

const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const userSignin = useSelector(state => state.userSignin);
  
  const { userInfo, error } = userSignin;
  
  const dispatch = useDispatch();
  
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
  }


    return (
        <>
        {(userInfo !== undefined && userInfo !== null ) ? <Redirect to="/adminblogs" /> : <></>}
        <div className='login-container'>
            <img className='login-img' src={imgGrow} alt={"AL NORTE"}/>
            <Card className="card-login">
                <div className="card-content">
            {error ? (
        <div>  <Alert color="danger" className="alert text-center" style={{borderRadius:10}}>
        {error}
      </Alert></div>
      ) : null}
              
              <Form className="login-form" onSubmit={submitHandler} autoComplete="none">
                  <InputGroup>
                <Label>Usuario</Label>
                <Input placeholder="Usuario" type="text" name="email" id="email"  onChange={(e) => setEmail(e.target.value)} autoComplete="new-email"/>
                </InputGroup>
                <InputGroup>
                <Label>Password</Label>
                <Input placeholder="Password" type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)} autoComplete="new-password"/>
                </InputGroup>
                
                <InputGroup>
                <Button className="btn-login" color="info" type="submit">
                  Iniciar Sesión
                </Button>
                </InputGroup>
              </Form>
              </div>
            </Card>
        </div>
        </>
    )
}

export default LoginScreen
