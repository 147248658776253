import React, { useEffect, useState } from 'react';
import './ComponentInfo.css';

const ComponentInfo = ({id, orientation, description, img}) => {  

  const imagenes = img;
  const [actualImg, setActualImg] = useState(1);
  
  useEffect(() => {
    if(img !== undefined){
      if(img.length === actualImg){      
        setTimeout(() => {
          setActualImg(1)
        }, 6000);
      }else{
        setTimeout(() => {
          setActualImg(actualImg+1)
        }, 6000);
      }
    }
    //eslint-disable-next-line
  }, [])


  return (
    <div className={`about-container`}>
      {orientation === "right" ?      
    <div className='about-info' id={id}>
      {description}
      <div className='about-img'>      
      {imagenes !== undefined ? 
        <img src={imagenes[0]} alt='about' className={`initial-img`} />   
        :
        <></>
      }
      </div>      
    </div>
    :
    <div className='about-info' id={id}>      
      <div className='about-img'>
      {imagenes !== undefined ? imagenes.map( (imagen, index) => 
      
        <img src={imagen} key={index} alt='about' className={ index +1 === actualImg ? `initial-img ` :  `initial-img img-hide-${index+1}`} />
        
        )
        :
        <></>
      }
      </div>      
      {description}
    </div>
    }

    </div>
  );
};

export default ComponentInfo;
