import React from 'react';
import imgLoading from '../../media/elements/loading.png';
import './LoadingComponent.css';
const LoadingComponent = ({size}) => {
    return (
        <div className="loading-container">
            {size < 100 ?
            <></>
            :
            <h1>AL NORTE</h1>
            }
            <img className="rotate" src={imgLoading} alt={"AL NORTE"} width={size} height={size}/>
            
        </div>
    )
}

export default LoadingComponent
